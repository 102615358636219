import React from "react"

import styled from "styled-components"
import Asciinema from "../components/Asciinema"
import Section from "../components/common/Section"
import SEO from "../components/seo"

const slackInviteLink =
  "https://join.slack.com/t/projectfurnace/shared_invite/zt-6xtmmx9z-my13TK0_fVommYQdaV~W6w"

export default function Page() {
  return (
    <div>
      <SEO title="Framework" />
      <Banner />
      <Features />
      <Constructs />
      {/* <Explainer /> */}
      {/* <SampleApps /> */}
      <Community />
      <Blog />
      <Integrations />
      {/* <GetStarted /> */}
      <Milestones />
    </div>
  )
}

const BannerSection = styled.div`
  background-image: linear-gradient(
    to right top,
    #bf1a3e,
    #cd2a37,
    #d93b2f,
    #e34c23,
    #eb5d12
  );
`

const Banner = () => (
  <div className="grid h-screen items-center gap-4 bg-gradient-to-b from-brand2 via-brand to-red-500  px-4 xl:px-0 text-white ">
    <div className="w-full lg:w-3/4 xl:w-1/2 mx-auto text-center">
      <h1 className="text-xl md:text-4xl">
        Build streaming data pipelines in minutes, respond to events in seconds.
      </h1>
      <div className="w-80 xl:w-full mx-auto">
        <Asciinema src="/ascii.cast" />
      </div>
      <h2 className="text-sm md:text-base">
        Furnace allows you to spend less time designing and managing
        infrastructure, leaving you more time to focus on doing great things
        with your data.
      </h2>
      <h3 className="text-sm md:text-base">
        A platform that Dev, Sec and Ops love.
      </h3>
    </div>
  </div>
)

const Features = () => {
  const features = [
    {
      title: "Platform Agnostic",
      content: "A single deployment experience across multiple platforms",
      icon: "/icons/platforms.svg",
    },
    {
      title: "Deploys Native",
      content: "A single deployment experience across multiple platforms",
      icon: "/icons/native.svg",
    },
    {
      title: "Minimal Cost",
      content: "A single deployment experience across multiple platforms",
      icon: "/icons/cost.svg",
    },
    {
      title: "Everything As Code",
      content: "A single deployment experience across multiple platforms",
      icon: "/icons/code.svg",
    },
    {
      title: "GitOps",
      content: "A single deployment experience across multiple platforms",
      icon: "/icons/gitops.svg",
    },
    {
      title: "We're Serverless",
      content: "A single deployment experience across multiple platforms",
      icon: "/icons/serverless.svg",
    },
  ]

  return (
    <Section className="flex-col py-16 text-center">
      <div className="w-full md:w-3/4 xl:w-1/2">
        <h1>An opinionated yet flexible streaming data pipeline solution.</h1>
        <h3 className="subheader">
          Furnace makes it easy to build highly effective teams that can respond
          to a continuously changing landscape, using best practices and a
          modern technology stack.
        </h3>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 auto-rows-fr gap-8 mt-6 w-5/6">
        {features.map(({ title, content, icon }) => (
          <div className="border rounded flex flex-col items-center justify-center p-8">
            <img src={icon} className="w-1/4" />
            <h2>{title}</h2>
            <h4>{content}</h4>
          </div>
        ))}
      </div>
    </Section>
  )
}

const Constructs = () => {
  const constructs = [
    {
      title: "Connectors",
      content:
        "Provides connectivity into external data sources and pushes into sources ready for processing.",
      icon: "/icons/Sources.svg",
    },
    {
      title: "Sources",
      content:
        "Defines source of data, usually this is stream from Apache Kafka or AWS Kinesis but can be anything you define. We provide some standard sources out of the box.",
      icon: "/icons/Sources.svg",
    },
    {
      title: "Taps",
      content:
        "Taps connect to a source. Their job is to parse and normalise data into a common format. A default set of Taps are provided and new Taps can be created by simply writing a serverless function.",
      icon: "/icons/Taps.svg",
    },
    {
      title: "Pipelines",
      content:
        "Pipelines create a linear path for data to flow through a chosen set of functions. Pipelines are connected to Taps and a Tap can feed multiple Pipelines.",
      icon: "/icons/Pipelines.svg",
    },
    {
      title: "Sinks",
      content:
        "A Sink is where your data arrives after it exits a Pipeline. A sink could be a data lake or storage bucket. Multiple Pipelines can feed into a Sink and a Pipeline can feed into multiple Sinks.",
      icon: "/icons/Sinks.svg",
    },
    {
      title: "Resources",
      content:
        "Resources are used to initiate resources native to the environment in which Furnace is being deployed.",
      icon: "/icons/Resources.svg",
    },
    {
      title: "Actions",
      content:
        "Once data has been processed by your pipelines, Actions make use of the structured data react and automate tasks in real-time.",
      icon: "/icons/Actions.svg",
    },
    {
      title: "Stacks",
      content:
        "A Stack is comprised of one or more end to end data flows into a logical container. A Stack can have multiple environments (Dev, Staging, Production).",
      icon: "/icons/Stack.svg",
    },
  ]

  return (
    <Section className="flex-col mt-32">
      <h1>Furnace Constructs</h1>
      <h2 className="subheader">
        Furnace provides a set of simple contructs that are the building blocks
        of your data pipeline.
      </h2>
      <img
        src="https://furnace.org/diagrams/furnace_illustration_generic.svg"
        className="w-5/6 md:w-1/2"
      />
      <div className="grid grid-cols-1 md:grid-cols-2 auto-rows-fr mt-6 w-full md:w-5/6 mx-auto">
        {constructs.map(({ title, content, icon }) => (
          <div className="grid md:flex flex-row items-center justify-center p-8">
            <img src={icon} className="w-16 mr-8" />
            <div className="flex flex-col ">
              <h2>{title}</h2>
              <h4 className="subheader">{content}</h4>
            </div>
          </div>
        ))}
      </div>
    </Section>
  )
}

const Explainer = () => (
  <Section className="h-96">
    <h1>See Furnace in Action. Zero to Hero in just 5 minutes.</h1>
  </Section>
)

const Community = () => (
  <div className="flex flex-col bg-black text-white h-full p-4 md:p-0 md:h-96 justify-center items-center">
    <h1 className="">Join our Community</h1>
    <h3 className="text-center md:text-left">
      We'd love you to help us build Furnace, in the form of Pull Requests,
      feedback from your journey or features you'd like to see.
    </h3>
    <div className="grid grid-cols-2 md:flex flex-row text-white justify-center items-center">
      <a
        className="btn-twitter my-2 mx-3 p-4"
        href="https://twitter.com/ProjectFurnace"
      >
        <span className="fab fa-twitter mr-2 fa-lg"></span>Twitter
      </a>
      <a className="btn-slack my-2 mx-3 p-4" href={slackInviteLink}>
        <span className="fab fa-slack mr-2 fa-lg"></span>Slack
      </a>
      <a
        className="btn-github my-2 mx-3 p-4"
        href="https://github.com/ProjectFurnace/furnace"
      >
        <span className="fab fa-github mr-2 fa-lg"></span>GitHub
      </a>
      <a
        className="btn-medium my-2 mx-3 p-4"
        href="https://medium.com/projectfurnace"
      >
        <span className="fab fa-medium mr-2 fa-lg"></span>Medium
      </a>
    </div>
  </div>
)

const Blog = () => (
  <Section className="flex-col mt-32">
    <h1 className="mb-8">The Furnace Blog</h1>
    <div className="grid gap-4 md:gap-0 md:flex justify-between p-4 md:p-0">
      <div className="w-full md:w-1/2 mr-0 md:mr-2 border rounded p-8">
        <a href="https://medium.com/projectfurnace/introducing-furnace-e4d7b1b51932">
          <h3>Introducing Furnace</h3>
          <p>
            Back in 2016, our team began researching the technologies in which
            Security Operations Center’s were using to protect the broad range
            of customers they monitor. Security Information and Event Management
            (SIEM)...
          </p>
        </a>
      </div>
      <div className="w-full md:w-1/2 ml-0 md:ml-2 border rounded p-8">
        <a href="https://medium.com/@davemound/threat-intelligence-with-honeypots-df06963384d3">
          <h3>Threat Intelligence with Honeypots</h3>
          <p>
            Over the past few months, we’ve been working on getting Furnace
            ready to release into Open Source. Over the past few weeks, I’ve
            been thinking of a blog series that would show it’s capabilities...
          </p>
        </a>
      </div>
    </div>
  </Section>
)

const Integrations = () => (
  <div className="flex flex-col mt-32 bg-gray-200 space-y-6 justify-center items-center px-4 md:px-24 py-12">
    <h1>Our Integrations</h1>
    <h3 className="subheader">
      Furnace aims to add native support for all major Cloud Providers, we'll be
      adding more in the coming months.
    </h3>
    <div className="grid grid-cols-2 md:flex flex-row justify-between space-x-4">
      <img src="/logos/aws.png" className="h-12 object-contain" />
      <img src="/logos/azure.png" className="h-12 object-contain" />
      <img src="/logos/gcp.png" className="h-12 object-contain" />
      <img src="/logos/github.png" className="h-12 object-contain" />
    </div>
  </div>
)

const Milestones = () => {
  const milestones = [
    {
      title: "Public Cloud Support",
      content:
        "Native support for Amazon Web Services, Microsoft Azure and Google Cloud Platform.",
    },
    {
      title: "Initial Language Support",
      content: "Support for Javascript/Node and Python.",
    },
    {
      title: "Cloud Agnostic Stacks",
      content:
        "The same stack can be deployed on AWS, Azure, Google Cloud with no changes to code or tooling.",
    },
    {
      title: "Furnace SDK",
      content:
        "Cloud agnostic programming model that abstracts common capabilities into a single interface.",
    },
    {
      title: "Cloud Resources",
      content: "Support for all available resources in all available clouds.",
    },
  ]

  return (
    <Section className="flex-col mt-32">
      <h1>Milestones</h1>
      <h3 className="subheader text-center md:text-left">
        We mean business and are following an aggressive roadmap in 2021
      </h3>
      <div className="w-11/12 md:w-2/5 mx-auto md:mx-0 mt-8">
        {milestones.map(({ title, content }, index) => (
          <div className="flex flex-row border-l pb-12">
            <span className="h-12 w-12 justify-center flex items-center rounded-full bg-circle text-white relative -left-6 inline-block">
              {index + 1}
            </span>
            <div className="w-full">
              <h4>{title}</h4>
              <p>{content}</p>
            </div>
          </div>
        ))}
      </div>
    </Section>
  )
}
