import React from "react"

export default function Section({ children, className }) {
  return (
    <div
      className={`flex items-center justify-center container mx-auto ${className}`}
    >
      {children}
    </div>
  )
}
